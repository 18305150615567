export const industryQuery = `// groq
*[_type == 'industry'][0] {
  seo {
    title,
    keywords,
    description
  },
  firstSection {
    title,
    industryImage {
      asset-> {
        url
      }
    }
  },
  secondSection {
    title,
    industries[] {
      title,
      "_rawDescription": description
    }
  },
  thirdSection {
    title,
    subTitle,
    buttonText
  }
}
`;
