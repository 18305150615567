import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, IndustryFirstSection, IndustrySecondSection, IndustryThirdSection } from '../components';
import { SEO_TEXT } from '../constants';
import { SanityIndustryType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { industryQuery } from '../utils/querys/industry/query';

interface Props {
  data: {
    sanityIndustry: SanityIndustryType;
  };
}

const Industries = ({ data: { sanityIndustry } }: Props) => {
  const [industryData, setIndustryData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(industryQuery);
      setIndustryData(data);
    };
    if (industryData === undefined && url.search === '?preview_mode=true') getData();
  }, [industryData]);
  return (
    <Fragment>
      <SEO title={sanityIndustry.seo?.title || ''} description={sanityIndustry.seo?.description || ''} />
      <IndustryFirstSection content={industryData ? industryData.firstSection : sanityIndustry.firstSection} />
      <IndustrySecondSection content={industryData ? industryData.secondSection : sanityIndustry.secondSection} />
      <IndustryThirdSection content={industryData ? industryData.thirdSection : sanityIndustry.thirdSection} />
    </Fragment>
  );
};

export default Industries;

export const pageQuery = graphql`
  query {
    sanityIndustry {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        industryImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      secondSection {
        title
        industries {
          title
          _rawDescription
        }
      }
      thirdSection {
        title
        subTitle
        buttonText
      }
    }
  }
`;
